// Resume.js
import './css/Resume.css';
import React from 'react';
import Header from "./Header";
import Footer from "./Footer";

const Resume = () => {
    return (
        <div style={{overflowY: "hidden", overflowX: "hidden"}}>
        <Header/>
            <div style={{
                backgroundColor: "#474E68", overflowX: "hidden"
            }}>
                <h1 className="header-center">Resume</h1>
                <section className="section">
                    <h2>Education:</h2>
                    <h3>Texas State University</h3>
                    <p>
                        Bachelor's of Science - Physics, Computer Science <br/>
                        Minor - Applied Mathematics <br />
                        Graduated: May 2023
                    </p>
                    <h3>Austin Community College</h3>
                    <p>
                        Associate of Science - Applied Mathematics <br/>
                        Graduated: December 2020
                    </p>

                    <h2>Experience:</h2>
                    <h3>Fullstack Developer & Computer Systems Analyst @ <a href="https://spexodisks.com/"> SpExoDisks
                    </a> </h3>
                    <h4>August 2020 - Present</h4>
                    <ul>
                        <li> <strong>Cutting-Edge Technology Proficiency: </strong>
                            Researched and integrated advanced tools like Django, MySQL, and Docker, optimizing web
                            functionality and user experience.
                        </li>
                        <li> <strong>Full-Stack Expertise: </strong>
                            Led end-to-end development cycles, from planning to AWS deployment,
                            ensuring seamless user interface and back-end integration.
                        </li>
                        <li> <strong>Structured Collaboration: </strong>
                            Maintained organized GitHub repositories, orchestrated clean MySQL databases,
                            and managed team dynamics through effective communication.
                        </li>
                        <li> <strong>Innovative Problem-Solving: </strong>
                            Implemented creative solutions for technical challenges, optimizing website performance
                            and enhancing user navigation.
                        </li>
                    </ul>

                    <h3>Learning Assistant @ <a href="https://www.txst.edu/physics/laprogram.html"> Texas State
                    </a> </h3>
                    <h4>August 2020 - December 2020, August 2021 - December 2021 (Seasonal Job)</h4>
                    <ul>
                        <li> <strong>Engaged Learning Atmosphere: </strong>
                            Applied pedagogical techniques for interactive Physics learning, fostering diverse
                            problem-solving approaches.
                        </li>
                        <li> <strong>Effective Student Support: </strong>
                            Delivered constructive feedback, encouraging engagement and comprehension, while showcasing
                            modern problem-solving techniques.
                        </li>
                        <li> <strong>Guided Academic Excellence: </strong>
                            Demonstrated best practices, promoting critical thinking and modern approaches,
                            elevating student learning outcomes.
                        </li>
                    </ul>

                    <h2>Projects</h2>

                    <h3>Kaggle Competition - House Price Prediction - <a href="https://github.com/isaacj96/HousingMarket"
                    > GitHub </a> </h3>
                    <h4>Machine Learning Project</h4>
                    <ul>
                        <li> Implemented machine learning algorithms for housing price prediction using Kaggle’s
                            dataset.
                        </li>
                        <li> Utilized feature engineering techniques to enhance data quality and explored diverse
                            regression models to build a precise predictive model.
                        </li>
                        <li> Demonstrated proficiency in Python programming,showcasing strong data analysis capabilities
                            and expertise in leveraging key libraries like scikit-learn, pandas, and matplotlib.
                        </li>
                    </ul>

                    <h3>MERN Stack</h3>
                    <h4>Web Development Project</h4>
                    <ul>
                        <li> Developed full-stack web applications using the MERN(MongoDB,Express.js,React.js,Node.js)
                            stack.
                        </li>
                        <li> Employed MongoDB for efficient data storage, Express.js for API development, React.js
                            for interactive UI, and Node.js for server-side logic.
                        </li>
                        <li> Utilized modern practices like component-based architecture, RESTful API design,
                            and state management for seamless functionality and user experience.
                        </li>
                    </ul>

                    <h3>Computing System Fundamentals</h3>
                    <h4>Real-Time Scheduling</h4>
                    <ul>
                        <li>Proficient in real-time scheduling for both single and multi-threaded CPU environments.
                        </li>
                        <li> Designed and implemented efficient algorithms such as RMS, EDF, priority-based, and MLFQ
                            to effectively prioritize tasks and allocate resources.
                        </li>
                        <li> Demonstrated expertise in C/C++programming, extensive knowledge of OS APIs,
                            and synchronization mechanisms.
                        </li>
                    </ul>

                    <h2>Technical Skills</h2>

                    <h3>Programming</h3>
                    <p>
                        C, C++, Python, Java, JavaScript, R, C#, SQL, Bash
                    </p>
                    <h3>Services, Tools, & Frameworks</h3>
                    <p>
                        Amazon Web Services, Docker, Linux, Git, Bash, React.js, Node.js, MERN stack, Django, RestAPI
                    </p>
                    <h3>Drawing & Typesetting</h3>
                    <p>
                        Office, LATEX, HTML, CSS
                    </p>
                    <h3>Languages</h3>
                    <p>
                        English (Native), Spanish (Beginner)
                    </p>

                    <h2>References</h2>
                    <p>
                        Available upon request. (To protect the privacy of my references,
                        I have not included their contact information here.)
                    </p>
                    <h2>Work Restrictions</h2>
                    <p>Eligible to work in the U.S with no restrictions.</p>

                </section>
            </div>
        <Footer/>
        </div>
    );
};

export default Resume;
