import React from 'react';
import './css/Body.css'; // You can create a separate CSS file for styling
import mypic from '../media/isaac.JPG'

const Body = () => {
    return (
        <div className="body">
            <div className="row">
                <div className="column left">
                    <section className="section">
                        <img src={mypic} alt="Isaac Jaramillo" className="profile-pic"/>
                    </section>
                </div>
                <div className="column right">
                    <section className="section">
                        <h2>About me</h2>
                        <p>
                            I am a recent graduate from Texas State University and studied Physics and Computer Science.
                            I hope to one day use my degrees in a useful way one day whether that be developing models
                            for
                            astrophysicist around the world or to help develop the next big thing in other meaningful
                            ways.
                        </p>

                        <p>
                            I am currently working on a <a href="https://spexodisks.com/"
                                                           style={{color: "#ffebcd"}}>project</a> with Dr. Andrea
                            Banzatti at Texas State University developing a
                            web application that will allow users to interact with a database of Spectroscopy data. This
                            project
                            involves using React, Node.js, Django, AWS, MySQL, and Docker. I have been responsible for
                            developing
                            the front-end by refactoring the code to be more modular and reusable, adding new features,
                            and
                            maintaining components. I've also been responsible for developing the back-end by creating
                            new API
                            functionalities and ensuring that the front-end and back-end are communicating properly. I
                            have also
                            been responsible for deploying the application to AWS using Docker and maintaining the
                            database.
                        </p>
                    </section>
                    <section className="section">
                        <h2>Contact </h2>
                        <ul>
                            <li>Email: <a href="mailto:isaac.jaramillo1996@gmail.com">isaac.jaramillo1996@gmail.com</a>
                            </li>
                            <li>Linkedin: <a href="https://www.linkedin.com/in/isaac-jaramillo-4956861ab/">
                                https://www.linkedin.com/in/isaac-jaramillo-4956861ab</a>/
                            </li>
                            <li>GitHub: <a href="https://github.com/isaacj96"> https://github.com/isaacj96 </a></li>
                        </ul>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default Body;
