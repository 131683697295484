// Footer.js

import React from 'react';
import './css/Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div>
                <p>&copy; {new Date().getFullYear()} Designed and Developed by Isaac Jaramillo</p>
            </div>
        </footer>
    );
};

export default Footer;
