import logo from './logo.svg';
import './App.css';
import Home from "./componets/Home";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Resume from "./componets/Resume";


export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/Home" element={<Home/>}/>
                <Route path="/Resume" element={<Resume/>}/>
                <Route exact path="/" element={<Home/>}/>
            </Routes>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);