import React from 'react';
import './css/Header.css';
import {Outlet, Link, Navigate} from "react-router-dom";


const Header = () => {
    return (
        <header className="header">
            {/*<h1 className="logo">Isaac Jaramillo</h1>*/}
            <a className="logo" href="/">Isaac Jaramillo</a>
            <nav className="nav">
                <ul className="nav-list">
                    <li className="nav-item">
                        <Link to="/">Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/resume">Resume</Link>
                    </li>
                    {/*<li className="nav-item"><a href="#">Home</a></li>*/}
                    {/*<li className="nav-item"><a href="#">Resume</a></li>*/}
                </ul>
            </nav>
        </header>
    );
}

export default Header;
