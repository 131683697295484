// Home.js
import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import Body from "./Body";

const Home = () => {
    return (
        <div style={{
            backgroundColor: "#474E68", overflowX: "hidden"
        }}>
            <Header/>
            <Body/>
            <Footer/>
        </div>
    );
};

export default Home;
